import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import footerStyle from "../../assets/jss/material-dashboard-react/components/footerStyle.jsx";
import LazyLoad from "react-lazyload";
import { useSelector, shallowEqual } from "react-redux";
import ReactWhatsappButton from "react-whatsapp-button";
import { RiFacebookLine } from "react-icons/ri";
import { CiInstagram } from "react-icons/ci";
import { CiYoutube } from "react-icons/ci";
import { PiDiscordLogoThin } from "react-icons/pi";
import { RiLinkedinLine } from "react-icons/ri";
import { RiTwitterXLine } from "react-icons/ri";





import { CiPhone, CiMail } from "react-icons/ci";


import "./Footer.css"

function Footer() {
  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  return (
    <>

      <section id="page-need_guidance-section" className="minhg need-guidance-form flex items-end md:items-center md:min-h-[680px] lg:min-h-[800px] relative z-[11] -mt-[2px] overflow-hidden rammm" style={{ minHeight: window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-gaming-courses-test/" || window.location.pathname === "/landingpage/masters-and-bachelors-programs/"  || window.location.pathname === "/landingpage/top-game-development-programs/" ? "300px" : "350px" }} data-locomotive-section-id="page-need_guidance" data-locomotive-section-type="need_guidance">


        <div className="min-h-full h-full w-full container-full1 relative z-[3] py-4">
          <div className="sm:max-w-[480px] lg:max-w-[560px]" style={{ maxWidth: "100%" }}>

            <div className="">








              <div className="grid-cols-1 sm:grid-cols-2 gap-3 mt-3 sm:mt-5 " style={{ width: "100%" }}>








                <div className={window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/game-development-programs/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/"|| window.location.pathname === "/landingpage/game-development-with-unity-programs/"|| window.location.pathname === "/landingpage/masters-and-bachelors-programs/" || window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ||  window.location.pathname === "/landingpage/certified-diploma-game-design-with-unreal-blueprints/" ||  window.location.pathname === "/landingpage/certified-diploma-unreal-game-development/" || window.location.pathname === "/landingpage/certified-diploma-unity-game-development/" ? "testfooter" : "footer-wrapper"}>
                  {isMobileState ? <div className="bottomFoot sticky-footer" style={{ display: window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" ||  window.location.pathname === "/landingpage/certified-diploma-game-design-with-unreal-blueprints/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/" || window.location.pathname === "/landingpage/game-development-programs/"  || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/"  || window.location.pathname === "/landingpage/masters-and-bachelors-programs/" || window.location.pathname === "/landingpage/top-game-development-programs/" ||  window.location.pathname === "/landingpage/certified-diploma-unreal-game-development/" ||window.location.pathname === "/landingpage/certified-diploma-unity-game-development/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? "none" : "block" }}>
                    <div className="mobile-sticky-footer ">
                      <div className="bottomFootLeft"><a target="_blank" rel="noopener noreferrer" href="tel:+918008002795"><CiPhone /> +91 8008002795</a></div>
                      {/* <div className="bottomFootRight"><a target="_blank" rel="noopener noreferrer" aria-label="Chat on WhatsApp" href="https://wa.me/918008002795"><FaWhatsapp /> Whatsapp</a></div> */}
                    </div>
                  </div> : null}

                  {isMobileState ? null : <div className="floatingIcon" style={{ display: window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/"  ||  window.location.pathname === "/landingpage/certified-diploma-unreal-game-development/" || window.location.pathname === "/landingpage/game-development-programs/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/"  || window.location.pathname === "/landingpage/masters-and-bachelors-programs/" || window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/certified-diploma-unity-game-development/"||  window.location.pathname === "/landingpage/certified-diploma-game-design-with-unreal-blueprints/"  ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/"|| window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? "none" : "block" }}>
                    <div className="App">
                      <ReactWhatsappButton
                        countryCode="91"
                        phoneNumber="8008002795"
                        animated
                        style={{ right: "2rem" }}
                        className="whatsappsivak"
                      />
                      <a href="tel:+918008002795" className="btn-floating-whatsapp durga btn-floating-whatsapp__animated" style={{ right: isMobileState ? "10rem" : "8rem", backgroundColor: "rgb(0, 164, 245)", backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: '40%' }}> </a>
                    </div>
                  </div>}

                  {window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/game-art-programs/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-programs/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-programs/"  || window.location.pathname === "/landingpage/best-gaming-courses-in-india/"||  window.location.pathname === "/landingpage/certified-diploma-game-design-with-unreal-blueprints/"  || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" || window.location.pathname === "/landingpage/certified-diploma-unity-game-development/" || window.location.pathname === "/landingpage/certified-diploma-unreal-game-development/" ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/" || window.location.pathname === "/landingpage/top-game-development-programs/" ? null : <div className="foot-details">
                    <LazyLoad height={76} offset={100} fadein={true}>
                      <div className="foot-item fadeInUp anime-delay3">
                        <h3 className="footer-title">Quick Links</h3>
                        <div className="contact-wrapper">
                          <div className="contact-wrapper-inner" style={{ display: window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" || window.location.pathname === "/landingpage/game-development-programs/" || window.location.pathname === "/landingpage/certified-diploma-unity-game-development/" ||  window.location.pathname === "/landingpage/certified-diploma-game-design-with-unreal-blueprints/" ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/"|| window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-programs/" ||  window.location.pathname === "/landingpage/certified-diploma-unreal-game-development/" ||window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? "none" : "block" }}>

                            {/* <div>
                              <p>Blogs</p>
                            </div> */}
                          </div>
                          <div className="contact-wrapper-inner" style={{ display: window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" || window.location.pathname === "/landingpage/game-development-programs/" || window.location.pathname === "/landingpage/certified-diploma-unity-game-development/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/"|| window.location.pathname === "/landingpage/masters-and-bachelors-programs/" || window.location.pathname === "/landingpage/top-game-development-programs/" ||  window.location.pathname === "/landingpage/certified-diploma-unreal-game-development/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? "none" : "block" }}>

                            {/* <div>
                              <p>Brochure</p>
                            </div> */}
                          </div>
                          <div className="contact-wrapper-inner" style={{ display: window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" || window.location.pathname === "/landingpage/game-development-programs/" ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/"|| window.location.pathname === "/landingpage/certified-diploma-unity-game-development/" ||  window.location.pathname === "/landingpage/certified-diploma-game-design-with-unreal-blueprints/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-programs/" || window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/certified-diploma-unreal-game-development/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? "none" : "block" }}>

                            {/* <div>
                              <p>E-book</p>
                            </div> */}
                          </div>
                          {/* <div className="contact-wrapper-inner">

                            <div>
                              <p>Why the Gaming Industry?</p>
                            </div>
                          </div> */}
                          <div className="contact-wrapper-inner" style={{ display: window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" || window.location.pathname === "/landingpage/game-development-programs/" ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/"|| window.location.pathname === "/landingpage/certified-diploma-unity-game-development/" ||  window.location.pathname === "/landingpage/certified-diploma-game-design-with-unreal-blueprints/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-programs/" || window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? "none" : "block" }}>

                            <div className="cont1">
                              <p><a href="/terms-and-conditions/">Terms and Conditions</a></p>
                            </div>
                          </div>
                          <div className="contact-wrapper-inner" style={{ display: window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/"|| window.location.pathname === "/landingpage/certified-diploma-unity-game-development/" || window.location.pathname === "/landingpage/game-development-programs/"||  window.location.pathname === "/landingpage/certified-diploma-game-design-with-unreal-blueprints/"  || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-programs/" ||  window.location.pathname === "/landingpage/certified-diploma-unreal-game-development/" ||window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? "none" : "block" }}>

                            <div className="cont1">
                              <p><a href="/privacy-policy/">Privacy Policy</a></p>
                            </div>
                          </div>
                          <div className="contact-wrapper-inner" style={{ display: window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" || window.location.pathname === "/landingpage/game-development-programs/" || window.location.pathname === "/landingpage/certified-diploma-unity-game-development/" ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/"|| window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" ||  window.location.pathname === "/landingpage/certified-diploma-game-design-with-unreal-blueprints/" || window.location.pathname === "/landingpage/masters-and-bachelors-programs/" ||  window.location.pathname === "/landingpage/certified-diploma-unreal-game-development/" || window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? "none" : "block" }}>

                            <div className="cont1">
                              <p><a href="/refund-policy/">Return/Refund Policy</a></p>
                            </div>
                            {/* <div className="cont1">
                              <p><a href="/sitemap/">Sitemap</a></p>
                            </div> */}
                          </div>
                        </div>
                      </div>

                    </LazyLoad>
                  </div>}
                  
                {window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/"  || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-programs/" || window.location.pathname === "/landingpage/certified-diploma-unity-game-development/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-programs/" ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/" ||  window.location.pathname === "/landingpage/certified-diploma-game-design-with-unreal-blueprints/" ||  window.location.pathname === "/landingpage/certified-diploma-unreal-game-development/" ||window.location.pathname === "/landingpage/game-art-programs/" || window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? null : <div className="foot-details">
                    <LazyLoad height={76} offset={100} fadein={true}>

                      <div className="foot-item fadeInUp anime-delay3">
                        <h3 className="footer-title">Contact Info</h3>
                        <div className="contact-wrapper">
                          <div className="cont1">
                            <div className="contact-wrapper-inner">

                              <CiPhone className="iconsmain" />
                              <div>
                                <p ><a href="tel:8008002794" rel="noopener noreferrer"> +91-8008002794 </a></p>

                              </div>
                            </div>
                            <div className="contact-wrapper-inner">
                              <CiPhone className="iconsmain" />
                              <div>
                                <p ><a href="tel:8008002795" rel="noopener noreferrer"> +91-8008002795 </a></p>

                              </div>
                            </div>

                            <div className="contact-wrapper-inner">
                              <CiMail className="iconsmain" />
                              <div>
                                <p><a href="mailto:info@backstagepass.co.in" rel="noopener noreferrer">info@backstagepass.co.in</a></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </LazyLoad>
                  </div>} 
  {window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/"  || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-programs/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-programs/" ||window.location.pathname === "/landingpage/game-art-programs/" || window.location.pathname === "/landingpage/certified-diploma-unity-game-development/" || window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/" ||
                  window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" ||  window.location.pathname === "/landingpage/certified-diploma-unreal-game-development/"||  window.location.pathname === "/landingpage/certified-diploma-game-design-with-unreal-blueprints/"  || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-programs/"  || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-programs/" || window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? <div className="foot-details">
                    <LazyLoad height={76} offset={100} fadein={true}>

                      <div className="foot-item fadeInUp anime-delay3">
                        <h3 className="footer-title" style={{ textAlign: 'center' }}>Contact Info</h3>
                        <div className="contact-wrapper">

                          <div className="contact-wrapper-inner">

                            <div className="fotterh">

                              <CiPhone className="icon" /><p> <a href="tel:8008002794" className="phone-number" rel="noopener noreferrer"> +91-8008002794</a></p>
                            </div>
                          </div>
                          <div className="contact-wrapper-inner">

                            <div className="fotterh">
                              <CiPhone className="icon" />
                              <p> <a href="tel:8008002795" className="phone-number" rel="noopener noreferrer"> +91-8008002795</a></p>

                            </div>
                          </div>


                        </div>
                      </div>
                    </LazyLoad>
                  </div> 
                  : null} 

{window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/"  || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-programs/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-programs/" ||window.location.pathname === "/landingpage/game-art-programs/" || window.location.pathname === "/landingpage/certified-diploma-unity-game-development/" || window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? null : null } 
                  {window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-programs/" ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-programs/" || window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? 
                 null : null}
                </div>
              </div>
            </div>


            <div>

              <LazyLoad height={76} offset={100} fadein={true}>
                <div className="foot-item fadeInUp anime-delay3">
                
                  {window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/"||  window.location.pathname === "/landingpage/certified-diploma-game-design-with-unreal-blueprints/"  || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/game-development-with-unreal-programs/" || window.location.pathname === "/landingpage/game-development-programs/" || window.location.pathname === "/landingpage/masters-and-bachelors-game-development-courses/" || window.location.pathname === "/landingpage/masters-and-bachelors-programs/" ||  window.location.pathname === "/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/"|| window.location.pathname === "/landingpage/game-art-programs/" || window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/top-game-development-programs/" || window.location.pathname === "/landingpage/certified-diploma-unity-game-development/" ||  window.location.pathname === "/landingpage/certified-diploma-unreal-game-development/" ||window.location.pathname === "/landingpage/best-gaming-courses-in-india/" ? null :

                    <div className="contact-wrapper ftr">
                       
                      <div className="contact-wrapper-inner md1">
                        <a href="https://discord.com/invite/fTYuzCJnsy" target="_blank" rel="noopener noreferrer"><PiDiscordLogoThin /></a>


                      </div>
                      <div className="contact-wrapper-inner md2">
                        <a href="https://www.instagram.com/backstagepassgaming/" target="_blank" rel="noopener noreferrer"><CiInstagram /></a>

                      </div>
                      <div className="contact-wrapper-inner md3">
                        <a href="https://www.facebook.com/bspgaminginstitute/" target="_blank" rel="noopener noreferrer"><RiFacebookLine /></a>

                      </div>
                      <div className="contact-wrapper-inner md4">
                        <a href="https://www.youtube.com/channel/UC8-3EcO9JQO8cRJklp8Gdvg" target="_blank" rel="noopener noreferrer"><CiYoutube /></a>

                      </div>

                      <div className="contact-wrapper-inner md4">
                        <a href="https://x.com/Bsp_Gaming" target="_blank" rel="noopener noreferrer"><RiTwitterXLine /></a>
                      </div>
                      <div className="contact-wrapper-inner md4">
                        <a href="https://www.linkedin.com/school/backstagepassinstitute/" rel="noopener noreferrer" target="_blank"><RiLinkedinLine />
                        </a>

                      </div>
                    </div>}
                  <LazyLoad height={76} offset={100} fadein={true}>
                    <div className="text-center foot-content fadeInUp anime-delay2">© A Subsidiary of PVR Memorial Educational Society 2025. All Rights Reserved</div>
                  </LazyLoad>
                </div>
              </LazyLoad>
            </div>


          













          </div>
        </div>
      </section>
    </>
    
  );
}

export default withStyles(footerStyle)(Footer);
